import React from "react";
import styled from "styled-components";
import "style/index.css";

// components
import { Body1, Heading2 } from "components/TextStyles";

export default function AboutUs() {
  return (
    <>
      <Wrapper className="container flexSpaceCenter">
        <AboutUsContent>
          <Heading2>Our Mission</Heading2>
          <Body1>
            Whilst everyone else is focused on adding years to life,{" "}
            <span style={{ color: "#A6BCFF" }}>
              we're on a mission to add life to years!
            </span>
          </Body1>
          <Body1>
            Being over 50, we didn't feel ready to retire{" "}
            <span style={{ fontStyle: "italic" }}>
              (etymology: retreat from battle)
            </span>{" "}
            and we certainly didn't feel over the hill, and so we have
            reimagined retirement.
          </Body1>
          <Body1>
            Some things definitely get better with age.{" "}
            <span style={{ color: "#A6BCFF" }}>
              Join our beta and live your best life!
            </span>
          </Body1>
        </AboutUsContent>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  @media (max-width: 960px) {
    order: 1;
    margin-top: 30px;
  }
`;

const AboutUsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60vw;
  gap: 32px;
  padding-top: 4vh;

  @media (max-width: 960px) {
    width: 100%;
  }
`;
