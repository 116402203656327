import React, { useState, useEffect } from "react";
import styled from "styled-components";

// components
import { GreymattersHeaderName } from "components/styledelements/GeneralStyledElements";
import { NoWrapScrollLink } from "components/styledelements/NoWrapScrollLink";
import { Heading3, Body2 } from "components/TextStyles";
import { Checkbox } from "@mui/material";
import Button from "components/styledelements/Button";

//assets
import LogoIcon from "../../../assets/svg/gmlogos/GMLogoSmallWhite";
import UncheckedIcon from "../../../assets/svg/icons/quiz/UncheckedQuiz";
import CheckedIcon from "../../../assets/svg/icons/quiz/CheckedQuiz";

export default function QuestionPage({
  questionObj,
  bgcolor,
  position,
  onNext,
  onBack,
  currentAnswer,
}) {
  const [selectedAnswer, setSelectedAnswer] = useState(currentAnswer);
  const [shuffledAnswers, setShuffledAnswers] = useState([]);

  const isAnswerSelected =
    selectedAnswer !== undefined && selectedAnswer !== null;

  const shuffleArray = (array) => {
    let shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const chooseAnswer = (key) => {
    setSelectedAnswer(key);
  };

  useEffect(() => {
    setShuffledAnswers(shuffleArray(questionObj.answers));
    // Reset selected answer when the question changes
    setSelectedAnswer(currentAnswer);
  }, [questionObj, currentAnswer]);

  return (
    <>
      <Wrapper bgcolor={bgcolor}>
        <CustomHeader />
        <ProgressLine progress={position} />
        <ContentWrapper>
          <LeftSide>
            <Heading3 lineHeight="110%" fontColor="black">
              {questionObj.question}
            </Heading3>
          </LeftSide>
          <AnswerList
            shuffledAnswers={shuffledAnswers}
            onCheckboxChange={chooseAnswer}
            selectedAnswer={selectedAnswer}
          />
        </ContentWrapper>
        <ButtonsWrapper>
          <Button
            color="transparent"
            onClick={() => onBack()}
            arrow="left"
            text="Back"
            style={{ paddingLeft: 0 }}
          />
          <Button
            disabled={!isAnswerSelected}
            color="black"
            onClick={() => isAnswerSelected && onNext(selectedAnswer)}
            arrow="right"
            text="Next"
          />
        </ButtonsWrapper>
      </Wrapper>
    </>
  );
}

const CustomHeader = () => (
  <Header>
    <NoWrapScrollLink to="/">
      <LogoIcon stroke="black" />
      <GreymattersHeaderName fontColor="black">
        Grey Matters
        <Trademark>®</Trademark>
      </GreymattersHeaderName>
    </NoWrapScrollLink>
  </Header>
);

const ProgressLine = ({ progress }) => (
  <ProgressWrapper>
    <FilledLine progress={progress} />
  </ProgressWrapper>
);

const AnswerList = ({ shuffledAnswers, onCheckboxChange, selectedAnswer }) => (
  <RightSide>
    {shuffledAnswers.map((answer) => (
      <Card
        key={answer.archetype}
        isSelected={selectedAnswer === answer.archetype}
        onClick={() => onCheckboxChange(answer.archetype)}
      >
        <CheckboxWrapper>
          <Checkbox
            disableRipple
            size="large"
            icon={<UncheckedIcon width={"32"} />}
            checkedIcon={<BlackCheckedIcon width={"32"} />}
            checked={selectedAnswer === answer.archetype}
          />
        </CheckboxWrapper>
        <Body2
          fontColor={selectedAnswer === answer.archetype ? "white" : "black"}
        >
          {answer.text}
        </Body2>
      </Card>
    ))}
  </RightSide>
);

// Styled components
const Wrapper = styled.section`
  min-height: 100vh;
  background-color: ${(props) => props.bgcolor};
  padding: 0 8vw;
`;

const Header = styled.section`
  width: 100%;
  padding: 30px 0;
`;

const ProgressWrapper = styled.div`
  width: 100%;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.2);
`;

const FilledLine = styled.div`
  width: ${(props) => props.progress * 100}%;
  height: 3px;
  background-color: white;
  transition: width 0.3s ease;
`;

const ContentWrapper = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 40px 0;

  @media (max-width: 760px) {
    flex-direction: column;
  }
`;

const LeftSide = styled.section`
  width: 55%;
  padding-bottom: 70px;

  @media (max-width: 760px) {
    width: 100%;
  }
`;

const RightSide = styled.section`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;

  @media (max-width: 760px) {
    align-items: flex-start;
    width: 100%;
  }

  @media (max-width: 560px) {
    align-items: center;
  }
`;

const Card = styled.section`
  position: relative;
  padding: 20px 70px 30px 20px;
  border-radius: 10px;
  background-color: ${(props) => (props.isSelected ? "black" : "white")};
  cursor: pointer;
  transition: background-color 0.2s ease; // Add this line
  width: 100%;

  @media (max-width: 760px) {
    width: 300px;
  }

  &:hover {
    background-color: ${(props) => (props.isSelected ? "black" : "#DDD")};
  }
`;

const CheckboxWrapper = styled.section`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

const Trademark = styled.span`
  font-size: 10px;
  vertical-align: top;
`;

const BlackCheckedIcon = styled(CheckedIcon)`
  & path {
    fill: black;
  }
`;

const ButtonsWrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-bottom: 50px;
`;
