import React from "react";
import styled from "styled-components";
import "style/index.css";

// assets
import BackgroundImage from "assets/img/backgrounds/about1.png";
import ArrowDown from "assets/svg/arrows/ArrowDown";

export default function Top() {
  return (
    <>
      <BackImage />
      <Gradient />
      <Wrapper id="home" className="container">
        <Title>
          {/* We've designed an experiential platform that unlocks the power of age
          diversity and the longevity economy. */}
          {/* Grey Matters is reimagining retirement and addressing the unintended
          consequences of our newfound longevity. */}
          {}
          Our AI-powered app delivers highly personalised longevity plans,
          helping individuals thrive in the second half of life.
        </Title>
        <ArrowDown marginTop="50px" />
      </Wrapper>
    </>
  );
}

const BackImage = styled.section`
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 0; /* Ensure it's behind other content */

  overflow: hidden;
  background-image: url(${BackgroundImage});

  background-color: lightgray;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 960px) {
    height: 60vh;
  }
`;

const Gradient = styled.section`
  position: absolute;
  width: 100%;
  height: 60vh;

  overflow: hidden;
  margin-top: 41vh;

  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(18, 20, 26, 1) 100%
  );
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 960px) {
    height: 36vh;
    margin-top: 25vh;
  }
`;

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  min-height: 100vh;
  gap: 32px;

  display: flex;
  flex-direction: column; /* stack children vertically */
  justify-content: center; /* center children vertically */
  align-items: flex-start; /* left-align children horizontally */
  order: 2;

  @media (max-width: 960px) {
    min-height: 100vh;
  }
`;

export const Title = styled.section`
  z-index: 1;
  color: var(--white, #fff);
  font-feature-settings: "clig" off, "liga" off;

  /* GM/Heading/Heading 1 */
  font-family: Inter;
  font-size: clamp(38px, 4.5vw, 65px);
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 96px */
  width: 70vw;

  margin-top: 80px;

  @media (max-width: 960px) {
    font-size: clamp(36px, 4vw, 56px);
    margin-top: 100px;
  }

  @media (max-width: 560px) {
    font-size: clamp(36px, 4vw, 50px);
    margin-top: 120px;
  }
`;
