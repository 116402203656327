import React from "react";
import styled from "styled-components";

// components
import { Heading2, Body1 } from "components/TextStyles";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "components/styledelements/Button";

// assets
import Dandelion from "assets/img/gmlogos/greyLogo.png";

export default function IntroPage({ onStart, loading }) {
  return (
    <>
      <CenterWrapper>
        <AlignTopWrapper>
          <LeftSide>
            <TitleWrapper>
              <Heading2 fontFamily="Valizas" fontWeight="500" lineHeight="130%">
                Let's Begin your Transformation
              </Heading2>
            </TitleWrapper>
            <BodyWrapper>
              <Body1>
                Are you ready to embark on an extraordinary journey to discover
                the true essence of your midlife adventure? Your path to
                self-discovery starts here by discovering your archetype.
                <br></br>
                <br></br>
              </Body1>
              <Body1>
                Ultimately, your archetype reflects your values and motivations,
                but it's the first step in designing your midlife roadmap.
              </Body1>
            </BodyWrapper>
            {loading ? (
              <CircularProgress color="inherit" sx={CircularProgressStyles} />
            ) : (
              <Button onClick={onStart} text="Let's Begin" arrow="right" />
            )}
          </LeftSide>
          <RightSide>
            <GreyDandelion />
          </RightSide>
        </AlignTopWrapper>
      </CenterWrapper>
    </>
  );
}

const GreyDandelion = styled.img.attrs(() => ({ src: Dandelion }))`
  position: relative;
  top: 8rem;
  right: 0;
  width: clamp(180px, 30vw, 500px);
  height: clamp(180px, 30vw, 500px);
  filter: brightness(120%);

  @media (max-width: 1100px) {
    top: 6rem;
  }

  @media (max-width: 860px) {
    margin-bottom: 80px;
  }
`;

const CenterWrapper = styled.section`
  background-color: rgb(57, 62, 73);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 120px 80px;

  @media (max-width: 860px) {
    padding-top: 120px;
    align-items: flex-start;
  }

  @media (max-height: 800px) {
    align-items: flex-start;
  }
`;

const AlignTopWrapper = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media (max-width: 860px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LeftSide = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

const TitleWrapper = styled.section`
  width: 120%;

  @media (max-width: 860px) {
    width: 80%;
  }
`;

const BodyWrapper = styled.section`
  width: 70%;

  @media (max-width: 860px) {
    width: 100%;
  }
`;

const RightSide = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-start;
`;

const CircularProgressStyles = {
  color: "white",
  flexGrow: "0",
  margin: "25px 0 0 30px",
};
