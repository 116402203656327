import React from "react";
import styled from "styled-components";
import "style/index.css";

// components
import { Heading4 } from "components/TextStyles";
import {
  TopTitle,
  TopWrapper,
  Gradient,
} from "screens/organisations/OrganisationStyledSections";

// assets
import BackgroundImage from "assets/img/greytasker/GreyTasker1.png";

export default function Top() {
  return (
    <>
      <BackImage />
      <Gradient />
      <TopWrapper className="container">
        <TopTitle fontFamily="Valizas">
          GreyTasker
          <span
            style={{
              fontSize: "32px",
              verticalAlign: "top",
              position: "absolute",
              marginTop: "-12px",
              marginLeft: "3px",
            }}
          >
            ®
          </span>
        </TopTitle>
        <Heading4>
          We can help you find passionate workers, curate the perfect new career
          pathway for individuals looking for their next challenge, or simply
          find a ‘Helping Hand’.
        </Heading4>
      </TopWrapper>
    </>
  );
}

const BackImage = styled.section`
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 0; /* Ensure it's behind other content */

  overflow: hidden;
  background-image: url(${BackgroundImage});

  background-color: lightgray;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 960px) {
    height: 60vh;
  }
`;
